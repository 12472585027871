var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"view-candidate-profile"},[_c('GoBackHeader',{staticClass:"mb-8",attrs:{"title":"Candidate Profile"}}),(!_vm.loading)?[_c('section',{staticClass:"profile-content"},[_c('div',{staticClass:"profile-content-body d-flex flex-column"},[_c('div',{staticClass:"profile-content-user-details base-card pa-0"},[_c('img',{staticClass:"profile-content-user-details-bg",attrs:{"alt":"bg","src":require('@/assets/images/profile-bg.png')}}),_c('div',{staticClass:"px-10 py-4 user-details d-flex flex-column flex-md-row"},[_c('div',[_c('ProfilePicture',{attrs:{"user":_vm.data.user}}),_c('div',{staticClass:"d-flex align-center mt-7"},[(
                    _vm.data.profile.profile &&
                    _vm.data.profile.profile.personal_details.name
                  )?_c('p',{staticClass:"mb-0 base-section-title user-name"},[(
                      _vm.data.profile.profile.personal_details.name.first_name
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.data.profile.profile.personal_details.name.first_name)+" ")]):_vm._e(),(
                      _vm.data.profile.profile.personal_details.name.middle_name
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.data.profile.profile.personal_details.name.middle_name)+" ")]):_vm._e(),(
                      _vm.data.profile.profile.personal_details.name.last_name
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.data.profile.profile.personal_details.name.last_name)+" ")]):_vm._e()]):_c('UserName',{attrs:{"user":_vm.data.user}}),_c('UserRating')],1),_c('UserDesignation',{attrs:{"user_profile":_vm.data.profile.profile ? _vm.data.profile.profile : null}}),_c('div',{staticClass:"mt-8 user-details-extra"},[_c('div',{staticClass:"details"},[(_vm.job_details_loading)?[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"100px","height":"20px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"100px","height":"20px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"100px","height":"20px"}})],1)],1)]:(!_vm.job_details_loading && _vm.job_details)?[_c('SkillMatch',{attrs:{"matched_score":_vm.job_details.score}}),_c('SkillGap',{attrs:{"matched_score":_vm.job_details.score}}),_c('UserExperience',{attrs:{"profile":_vm.data.profile}})]:[_c('SkillMatch',{attrs:{"matched_score":0,"error":true}}),_c('SkillGap',{attrs:{"matched_score":0,"error":true}}),_c('UserExperience',{attrs:{"profile":_vm.data.profile}})]],2)])],1),_c('div',{staticClass:"\n                mt-8 mt-md-0\n                ms-md-auto\n                d-flex\n                align-center\n                flex-column\n                justify-space-between\n              "},[_c('div',{staticClass:"d-flex align-center mb-2"},[_vm._l((_vm.data.user.social_links),function(social_account,index){return [_c('v-btn',{key:index,style:(!social_account.url ? 'opacity: 0.6' : ''),attrs:{"href":!social_account.url.startsWith('http')
                        ? 'https://' + social_account.url
                        : social_account.url,"disabled":!social_account.url,"target":"_blank","icon":"","large":"","color":"primary"}},[_c('p',[_vm._v(_vm._s(social_account.link))]),_c('img',{staticClass:"user-details-social-icon",attrs:{"alt":social_account.platform,"src":_vm.social_icons[social_account.platform]}})])]})],2),_c('v-btn',{staticClass:"my-4 base-submit-button user-details-btn details-btn",attrs:{"disabled":!_vm.data.user.resume_uri,"href":_vm.data.user.resume_uri,"download":""}},[_c('inline-svg',{staticClass:"mr-3",attrs:{"transformSource":_vm.transform_resume_icon,"src":require('@/assets/icons/bold/document-text.svg')}}),_vm._v(" "+_vm._s(_vm.$t("shared.resume"))+" ")],1),_c('v-btn',{staticClass:"base-submit-button user-details-btn px-10",attrs:{"disabled":_vm.interview_exist,"loading":_vm.get_poke_candidate_loading || _vm.interview_status_loading},on:{"click":_vm._poke_candidate}},[(!_vm.interview_exist)?_c('inline-svg',{staticClass:"mr-3",attrs:{"transformSource":_vm.transform_poke_icon,"src":require('@/assets/icons/linear/interactive.svg')}}):_vm._e(),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.interview_status_msg))])],1)],1)])]),_c('HighlightsAwards',{attrs:{"highlights":_vm.data.profile.profile && _vm.data.profile.profile.media
              ? _vm.data.profile.profile.media
              : []}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('EducationDetails',{attrs:{"education":_vm.data.profile.profile
                  ? _vm.data.profile.profile.education_detailed
                  : null}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('CertificationDetails',{attrs:{"certifications":_vm.data.profile.profile &&
                _vm.data.profile.profile.certification_detailed
                  ? _vm.data.profile.profile.certification_detailed
                  : {}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('WorkExperience',{attrs:{"work_details":_vm.data.profile.profile
                  ? _vm.data.profile.profile.work_history_detailed
                  : {}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Personality',{attrs:{"assessment_user_id":_vm.data.user.id,"personality_details":_vm.data.profile.profile
                  ? _vm.data.profile.profile.personality_detailed
                  : null}})],1)],1),_c('SkillsGroup',{attrs:{"active_skill_group":_vm.active_skill_group},on:{"update_skill_group":_vm.update_skill_group}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.job_details ? 6 : 12}},[_c('SkillsChips',{attrs:{"loading":_vm.loading,"skills":_vm.data.profile.skills,"active_skill_group":_vm.active_skill_group,"title":_vm.$t('shared.profile.skills-title'),"assessment":_vm.data.profile.assessment}})],1),(_vm.job_details)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.job_details_loading && _vm.job_details)?[_c('SkillsChips',{attrs:{"loading":_vm.job_details_loading,"skills":_vm.job_details.matched,"active_skill_group":_vm.active_skill_group,"title":_vm.$t('candidate.jobs.matched-skills')}})]:[_c('SkillsChips',{attrs:{"loading":_vm.job_details_loading,"skills":[],"active_skill_group":_vm.active_skill_group,"title":_vm.$t('candidate.jobs.matched-skills')}})]],2):_vm._e()],1),(_vm.job_details)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.job_details_loading && _vm.job_details)?[_c('SkillsChips',{attrs:{"loading":_vm.job_details_loading,"skills":_vm.job_details.matched.concat(_vm.job_details.gap),"active_skill_group":_vm.active_skill_group,"title":_vm.$t('recruiter.view-job.required-skills')}})]:[_c('SkillsChips',{attrs:{"loading":_vm.job_details_loading,"skills":[],"active_skill_group":_vm.active_skill_group,"title":_vm.$t('recruiter.view-job.required-skills')}})]],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.job_details_loading && _vm.job_details)?[_c('SkillsChips',{attrs:{"loading":_vm.job_details_loading,"skills":_vm.job_details.gap,"active_skill_group":_vm.active_skill_group,"title":_vm.$t('candidate.dashboard.skill-gap')}})]:[_c('SkillsChips',{attrs:{"loading":_vm.job_details_loading,"skills":[],"active_skill_group":_vm.active_skill_group,"title":_vm.$t('candidate.dashboard.skill-gap')}})]],2)],1):_vm._e()],1)])]:[_c('UserProfileLoader')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }